import React from "react";

function Contact() {
   //give contact information and send mail to the company
    return (
        <div>
            <h1 className="home">Contact Us</h1>
            <div className="container">
            <p>Email:dls1109@gmai.company</p>
            <p>Phone: 123-456-7890</p>
            <p>Address: 123 Main St, City, Country</p>
            </div>
        </div>
    );


   }

export default Contact;
